<template>
	<b-container fluid>
		<b-row>
			<b-col lg="4">
				<iq-card body-class="iq-card-body pl-0 pr-0 pt-0">
					<template v-slot:body>
						<div class="docter-details-block">
							<div class="doc-profile-bg bg-primary" style="height: 50px"></div>
							<div class="docter-profile text-center">
								<div class="profile-img-edit mt-3">
									<b-img
										:src="user.profile"
										class="profile-pic height-150 width-150"
										alt="profile-pic"
									/>
									<input type="hidden" v-model="user.profile" />
									<div class="p-image">
										<div class="position-relative">
											<b-form-file
												class="h-100 position-absolute"
												accept="image/*"
												style="opacity: 0"
												@change="previewImage"
											></b-form-file>
											<i class="ri-pencil-line upload-button"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="text-center mt-3 pl-3 pr-3">
								<h4>
									<b>
										{{ `${data.first_last_name} ${data.second_last_name}, ${data.name}` }}
									</b>
								</h4>
								<p>Paciente</p>
							</div>
							<hr />
							<ul
								class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
							>
								<li class="text-center">
									<h3 class="counter">{{ appointments.length }}</h3>
									<span>Citas</span>
								</li>
								<li class="text-center">
									<h3 class="counter">{{ treatments.length }}</h3>
									<span>Tratamientos</span>
								</li>
							</ul>
						</div>
					</template>
				</iq-card>
				<iq-card body-class="iq-card-body">
					<template v-slot:body>
						<div class="iq-card-header d-flex justify-content-between">
							<div class="iq-header-title">
								<h4 class="card-title">Información Personal</h4>
							</div>
						</div>
						<div class="iq-card-body">
							<div class="about-info m-0 p-0">
								<div class="row">
									<div class="col-4"><b>Nombres:</b></div>
									<div class="col-8">{{ data.name }}</div>
									<div class="col-4"><b>Apellidos:</b></div>
									<div class="col-8">
										{{ `${data.first_last_name} ${data.second_last_name}` }}
									</div>
									<div class="col-4"><b>Edad:</b></div>
									<div class="col-8">{{ `${data.age}` }}</div>
									<div class="col-4"><b>Sexo:</b></div>
									<div class="col-8">{{ `${data.gender}` }}</div>
									<div class="col-4"><b>Correo Electrónico:</b></div>
									<div class="col-8">
										<a :href="`mailto:${data.email}`"> {{ `${data.email}` }} </a>
									</div>
									<div class="col-4"><b>Teléfono:</b></div>
									<div class="col-8">
										<a :href="`tel:${data.phone}`">{{ `${data.phone}` }}</a>
									</div>
									<div class="col-4"><b>Estado Civil:</b></div>
									<div class="col-8">{{ `${data.civil_status}` }}</div>
									<div class="col-4"><b>Ciudad:</b></div>
									<div class="col-8">{{ `${data.city}` }}</div>
									<div class="col-4"><b>Provincia:</b></div>
									<div class="col-8">{{ `${data.province}` }}</div>
								</div>
							</div>
						</div>
					</template>
				</iq-card>
			</b-col>
			<b-col lg="8">
				<b-row>
					<b-col md="12">
						<iq-card>
							<template v-slot:headerTitle>
								<h4 class="card-title">Citas</h4>
							</template>
							<template v-slot:body>
								<table class="table mb-0 table-borderless">
									<thead>
										<tr>
											<th scope="col">Fecha</th>
											<th scope="col">Doctor</th>
											<th scope="col">Servicio</th>
											<th scope="col">Notas</th>
											<th scope="col">Estado</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in appointments" :key="index">
											<td>{{ item.date_format }}</td>
											<td>{{ item.doctor }}</td>
											<td>{{ item.service }}</td>
											<td>{{ item.notes }}</td>
											<td>
												<span class="badge badge-primary">{{ item.status }}</span>
											</td>
										</tr>
									</tbody>
								</table>
							</template>
						</iq-card>
					</b-col>
					<b-col md="12">
						<iq-card>
							<template v-slot:headerTitle>
								<h4 class="card-title">Tratamientos</h4>
							</template>
							<template v-slot:body>
								<table class="table mb-0 table-borderless">
									<thead>
										<tr>
											<th scope="col">Fecha</th>
											<th scope="col">Doctor</th>
											<th scope="col">Diagnostico</th>
											<th scope="col">Descripción</th>
											<th scope="col">Medicación</th>
											<th scope="col">Objetivo</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in treatments" :key="index">
											<td>{{ item.date }}</td>
											<td>{{ item.doctor }}</td>
											<td>{{ item.diagnostic_title }}</td>
											<td>{{ item.description }}</td>
											<td>{{ item.medication }}</td>
											<td>{{ item.objective }}</td>
										</tr>
									</tbody>
								</table>
							</template>
						</iq-card>
					</b-col>
					<b-col lg="12">
						<iq-card class-name="iq-card-block" iq-card-stretch iq-card-height>
							<template v-slot:headerTitle>
								<h4 class="card-title">Historial de Glucosa</h4>
							</template>
							<template v-slot:body>
								<ApexChart
									v-if="showChart"
									element="home-chart-06"
									:chartOption="chartData"
								/>
							</template>
						</iq-card>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import { xray } from "../../../config/pluginInit";
import { Toast } from "../../../mixins/toast";
import api from "../../../services/api";

export default {
	async mounted() {
		xray.index();
		await this.getData();
		await this.getAppointments();
		await this.getTreatments();
	},
	data() {
		return {
			showChart: false,
			data: {},
			appointments: [],
			treatments: [],
			user: {
				profile: require("../../../assets/images/user/1.jpg"),
			},
			chartData: {
				series: [
					{
						name: "Glucosa",
						data: [],
					},
				],
				chart: {
					height: 350,
					type: "area",
					zoom: {
						enabled: false,
					},
				},
				colors: ["#3892A6"],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					type: "datetime",
					categories: [],
				},
				tooltip: {
					x: {
						format: "dd/MM/yy",
					},
				},
			},
		};
	},
	methods: {
		async getData() {
			const user = JSON.parse(localStorage.getItem("user"));
			const userID = localStorage.getItem("userEdit");
			try {
				const { data } = await api.get(`/patients/${userID ?? user.patient_id}`);
				this.data = data.data;

				const date = this.data.glucose.map((item) => item.date);
				const quantity = this.data.glucose.map((item) => item.quantity);

				this.chartData.series[0].data = quantity;
				this.chartData.xaxis.categories = date;
				this.showChart = true;

				this.user.profile = this.data.photo;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async getAppointments() {
			const user = JSON.parse(localStorage.getItem("user"));
			const userID = localStorage.getItem("userEdit");
			try {
				const { data } = await api.get(
					`/patients/${userID ?? user.patient_id}/appointments`
				);
				this.appointments = data.data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async getTreatments() {
			const user = JSON.parse(localStorage.getItem("user"));
			const userID = localStorage.getItem("userEdit");
			try {
				const { data } = await api.get(
					`/patients/${userID ?? user.patient_id}/treatments`
				);
				this.treatments = data.data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		previewImage(event) {
			const input = event.target;

			if (input.files && input.files[0]) {
				this.uploadPhoto(input.files[0]);
				const reader = new FileReader();

				reader.onload = (e) => {
					this.user.profile = e.target.result;
				};

				reader.readAsDataURL(input.files[0]);
			}
		},
		async uploadPhoto(file) {
			const user = JSON.parse(localStorage.getItem("user"));
			const userID = localStorage.getItem("userEdit");
			try {
				const formData = new FormData();
				formData.append("photo", file);

				await api.post(`/patients/${userID ?? user.patient_id}/photo`, formData);

				Toast.fire({
					icon: "success",
					title: "Foto Actualizada",
				});
			} catch (error) {
				const errorMessage =
					error?.response?.data?.message || "Error al actualizar la configuración";
				Toast.fire({
					icon: "error",
					title: errorMessage,
				});
			}
		},
	},
};
</script>
